<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :server-items-length="totalCount"
    :hide-default-footer="true"
  >
    <template v-slot:[`item.orderDate`]="{ item }">
      <span v-text="item.orderDate ? formatDate(item.orderDate, 'string') : ''"></span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-end">
        <v-btn icon small @click="$emit('editItem', item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon small @click="$emit('deleteItem', item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import datePickerFormatterMixin from '@/mixins/datePickerFormatter'

export default {
  mixins: [datePickerFormatterMixin],
  props: {
    totalCount: {
      type: Number,
      required: true
    },
    isFetchingItems: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: 'Tipi', value: 'orderType', sortable: false },
        { text: 'Nömrəsi', value: 'number', sortable: false },
        { text: 'Tarixi', value: 'orderDate', sortable: false },
        { text: 'Məsul şəxs', value: 'creatorUser', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      options: {}
    }
  }
}
</script>
