<template>
  <v-container fluid>
    <section class="m-inner-section ma-4">
      <div class="m-inner-page">
        <v-row align="center" class="my-0">
          <v-col md="6">
            <h6 class="text-h6 gray--text text--darken-3">Əmr</h6>
          </v-col>
          <v-col md="6">
            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                depressed
                @click="addOrder()"
                v-if="page === 'edit'"
              >
                <v-icon class="mr-2" medium> mdi-plus </v-icon>
                <span> Əlavə et </span>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>

      <List
        :totalCount="totalCount"
        :items="items"
        :isFetchingItems="isFetchingItems"
        @editItem="handleEditItem"
        @deleteItem="handleDeleteItem"
      />

      <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="1000px"
        v-if="page === 'edit'"
      >
        <v-card>
          <div class="py-5 px-4 border-bottom">
            <span
              class="text-h6 gray--text text--darken-3 font-weight-regular"
              v-text="selectedOrder ? 'Əmrin redaktəsi' : 'Yeni əmr'"
            >
            </span>
          </div>
          <div class="px-4 pt-4 pb-1">
            <v-form
              id="employeeOrderForm"
              ref="employeeOrderForm"
              @submit.prevent="validateForm"
              v-model="employeeOrderFormValid"
              lazy-validation
            >
              <v-row class="my-0">
                <v-col cols="12" class="mb-3">
                  <v-select
                    label="Tipi*"
                    filled
                    dense
                    hide-details
                    item-text="value"
                    item-value="id"
                    :items="orderTypes"
                    v-model="form.orderTypeId"
                    :rules="rules.orderType"
                  />
                </v-col>
                <v-col cols="6" class="mb-3">
                  <v-text-field
                    hide-details
                    filled
                    dense
                    label="Nömrəsi*"
                    v-model="form.number"
                    :rules="rules.number"
                  />
                </v-col>
                <v-col cols="6" class="mb-3">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="orderDateFormatted"
                        label="Tarixi*"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        filled
                        dense
                        :rules="rules.orderDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="orderDate"
                      :month-format="getMonthFormat"
                      :header-date-format="getHeaderDateFormat"
                      :title-date-format="getTitleDateFormat"
                      :weekday-format="getWeekdayFormat"
                      :first-day-of-week="1"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
            <v-btn color="gray lighten-3" depressed @click="closeDialog">
              Bağla
            </v-btn>
            <v-btn
              depressed
              color="success"
              form="employeeOrderForm"
              type="submit"
              :loading="loading"
            >
              {{ selectedOrder ? "Yadda saxla" : "Yarat" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ConfirmationPopup
        id="delete-order-modal"
        title="Əmrin silinməsi"
        v-if="deletedOrder"
        :dialog="confirmationDialog"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
        :confirmText="'Sil'"
      >
        Əmri silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </section>
  </v-container>
</template>

<script>
import List from "./components/List/index.vue";
import API from "@/services";
import datePickerFormatterMixin from "@/mixins/datePickerFormatter";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import bus from "../../../../../../event-bus";

export default {
  props: {
    page: {
      type: String,
      required: true,
    },
  },
  mixins: [datePickerFormatterMixin],
  data() {
    return {
      form: {
        orderTypeId: null,
        number: null,
        orderDate: null,
      },
      rules: {
        orderType: [(v) => !!v || "Tipi tələb olunur"],
        number: [(v) => !!v || "Nömrəsi tələb olunur"],
        orderDate: [(v) => !!v || "Tarixi tələb olunur"],
      },
      items: [],
      isFetchingItems: false,
      totalCount: 0,
      dialog: false,
      employeeOrderFormValid: false,
      loading: false,
      orderDate: null,
      dateMenu: false,
      orderTypes: [],
      selectedOrder: null,
      deletedOrder: null,
      confirmationDialog: false,
    };
  },
  components: {
    List,
    ConfirmationPopup,
  },
  watch: {
    orderDate: {
      handler(val) {
        this.form.orderDate = val;
      },
    },
  },
  computed: {
    orderDateFormatted: {
      get() {
        return this.formatFormDate(this.orderDate);
      },
      set(value) {
        this.value = value;
      },
    },
  },
  methods: {
    resetForm() {
      this.form = {
        orderTypeId: null,
        number: null,
        orderDate: null,
      };
      this.orderDate = null;
      this.$refs.employeeOrderForm.reset();
      this.dialog = false;
    },
    formatFormDate(date) {
      this.form.orderDate = date;
      return this.formatDate(date, "string");
    },
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.fetchHREmployeeOrders(this.$route.params.id);
      }
    },
    fetchHREmployeeOrders(id) {
      this.isFetchingItems = true;
      API.fetchHREmployeeOrders(id)
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməkdaşın əmrlərini əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    validateForm() {
      const isValid = this.$refs.employeeOrderForm.validate();

      if (this.employeeOrderFormValid && isValid) {
        this.selectedOrder
          ? this.handleEditOrder(this.form)
          : this.handleAddOrder(this.form);
      }
    },
    async fetchHROrderTypes() {
      this.orderTypes = await API.fetchHROrderTypes();
    },
    addOrder() {
      this.selectedOrder = null;
      this.dialog = true;
    },
    handleEditItem(item) {
      this.loading = true;

      API.getHREmployeeOrder(item.employeeId, item.id)
        .then((response) => {
          this.selectedOrder = JSON.parse(JSON.stringify(response));
          this.form = { ...response };
          this.orderDate = this.dateToPickerFormat(
            response.orderDate,
            "string"
          );
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməkdaşın əmrini əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });

      this.dialog = true;
    },
    handleDeleteItem(item) {
      this.deletedOrder = { ...item };
      this.confirmationDialog = true;
    },
    closeDialog() {
      this.selectedOrder = null;
      this.orderDate = [];
      this.resetForm();
      this.dialog = false;
    },
    handleAddOrder(formData) {
      this.insertHREmployeeOrder(this.$route.params.id, formData);
    },
    handleEditOrder(formData) {
      this.updateHREmployeeOrder(
        this.selectedOrder.employeeId,
        this.selectedOrder.id,
        formData
      );
    },
    handleDeleteConfirmation() {
      API.deleteHREmployeeOrder(
        this.deletedOrder.employeeId,
        this.deletedOrder.id
      )
        .then(() => {
          this.fetchHREmployeeOrders(this.deletedOrder.employeeId);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Əmr silindi.",
          });
          this.handleDeleteCancellation();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əmri silmək mümkün olmadı.",
          });
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.deletedOrder = null;
    },
    insertHREmployeeOrder(id, data) {
      this.loading = true;
      API.insertHREmployeeOrder(id, data)
        .then(() => {
          this.closeDialog();
          this.fetchHREmployeeOrders(id);
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Əmr əlavə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əmr əlavə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateHREmployeeOrder(employeeId, id, data) {
      this.loading = true;
      API.updateHREmployeeOrder(employeeId, id, data)
        .then(() => {
          this.fetchHREmployeeOrders(this.$route.params.id);
          this.closeDialog();
          bus.$emit("notificationMessage", {
            color: "success",
            text: "Əmr redaktə edildi.",
          });
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əmri redaktə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.checkID();
    this.fetchHROrderTypes();
  },
};
</script>
<style></style>
