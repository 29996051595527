<template>
  <Orders :page="'edit'" />
</template>

<script>
import Orders from '../../../Shared/Pages/Orders/index.vue'

export default {
  components: {
    Orders
  }
}
</script>
